// import dayjs from 'dayjs';
const common = {
  // 深拷贝
  copy: (obj, cache = []) => {
    if (common.isDate(obj)) return new Date(obj.getTime());
    if (common.isJson(obj) || common.isArray(obj)) {
      let newObj = common.isJson(obj) ? {} : [];
      const keys = Object.keys(obj);
      for (let i = 0, len = keys.length; i < len; i++) {
        newObj[keys[i]] = common.copy(obj[keys[i]]);
      }
      return newObj;
    } else if (common.isObject(obj) && obj !== null) { // 当参数为 object 类型并且不是 json 和数组时
      let newObj = {};
      for (let key in obj) {
        newObj[key] = common.copy(obj[key]);
      }
      return newObj;
    }
    return obj;
  },
  // 是否数组
  isArray: arr => {
    if (typeof arr === 'undefined') return false;
    if (Array.isArray) return Array.isArray(arr);
    return Object.prototype.toString.call(arr).slice(8, -1) === 'Array';
  },
  // 是否对象, 包含数组和json
  isObject: (obj) => {
    if (common.isArray(obj)) return true;
    return Object.prototype.toString.call(obj).slice(8, -1) === 'Object';
  },
  // 是否 JSON
  isJson: json => {
    if (common.isArray(json)) return false;
    return Object.prototype.toString.call(json).slice(8, -1) === 'Object';
  },
  // 是否数字类型
  isNumber: num => {
    return typeof num === "number";
  },
  // 是否字符串
  isString: str => {
    return typeof str === "string";
  },
  // 是否为空， 包括 空数组，空对象， null、空字符串、undefined、NaN
  isEmpty: (value, type) => {
    if (common.isDate(value)) return isNaN(value.getTime());
    if (common.isString(value)) {
      return common.isBoolean(type) && type ? value.trim() === '' : value === '';
    }
    if (common.isNumber(value)) return isNaN(value);
    if (common.isJson(value) || common.isArray(value)) {
      const arr = Object.values(value);
      if (!type) return arr.length <= 0;
      return Object.keys(common.removeEmpty(arr, true)).length <= 0;
    }
    return value === null || common.isUndefined(value);
  },
  // 是否未定义
  isUndefined: (val) => {
    return typeof val === 'undefined';
  },
  // 是否boolean
  isBoolean: val => {
    return typeof val === "boolean";
  },
  // 是否函数
  isFunction: fun => {
    return typeof fun === "function";
  },
  // 是否时间
  isDate: val => {
    return Object.prototype.toString.call(val).slice(8, -1) === "Date";
  },
  // 是否正则
  isRegExp: val => {
    return Object.prototype.toString.call(val).slice(8, -1) === "RegExp";
  },
  isSymbol: val => {
    // 是否Symbol函数
    return Object.prototype.toString.call(val).slice(8, -1) === "Symbol";
  },
  // 是否Promise对象
  isPromise: val => {
    return Object.prototype.toString.call(val).slice(8, -1) === "Promise";
  },
  // 是否Set对象
  isSet: val => {
    return Object.prototype.toString.call(val).slice(8, -1) === "Set";
  },
  // 数组去重
  arrRemoveRepeat: arr => {
    return Array.from(new Set(arr));
  },
  // 移除对象中所有值的 2 端空格
  trim: (obj, exceptionKey) => {
    const exception = common.isString(exceptionKey)
      ? [exceptionKey]
      : exceptionKey || [];
    if ((common.isString(obj) && obj === "") || common.isEmpty(obj)) return;
    if (common.isString(obj)) {
      return obj.trim();
    }
    if (common.isArray(obj)) {
      obj.forEach((item, index) => {
        if (common.isArray(item) || common.isJson(item)) {
          common.trim(item, exception);
        } else if (common.isString(item)) {
          obj[index] = item.trim();
        }
      });
    } else if (common.isJson(obj)) {
      Object.keys(obj).forEach(key => {
        if (common.isArray(obj[key]) || common.isJson(obj[key])) {
          common.trim(obj[key], exception);
        } else if (common.isString(obj[key]) && !exception.includes(key)) {
          obj[key] = obj[key].trim();
        }
      });
    }
    return obj;
  },
  /* 获取全部url参数,并转换成json对象 */
  getUrlParams(url) {
    const newUrl = decodeURIComponent(url || window.location.href);
    if (newUrl.indexOf("?") === -1) return {};
    const urlOption = newUrl.substring(newUrl.indexOf("?") + 1);
    const urlList = urlOption.split("&");
    let urlJson = {};
    urlList.forEach(item => {
      const pos = item.indexOf("=");
      urlJson[item.substring(0, pos)] = item.substring(pos + 1);
    });
    return urlJson;
  },
  // 获取元素坐标(元素的左上角为基点)
  getElementOffset(element) {
    const newElement =
      typeof element === "string" ? document.querySelector(element) : element;
    let offset = { x: 0, y: 0 };
    let current = newElement.offsetParent;
    offset.x += newElement.offsetLeft;
    offset.y += newElement.offsetTop;
    while (current !== null) {
      offset.x += current.offsetLeft || 0;
      offset.y += current.offsetTop || 0;
      current = current.offsetParent;
    }
    return offset;
  },
  // 获取元素所在被滚动距离
  getElementScrollTop(element) {
    const newElement =
      typeof element === "string" ? document.querySelector(element) : element;
    let top = 0;
    let current = newElement.parentNode;
    while (current !== null) {
      top += current.scrollTop || 0;
      current = current.parentNode;
    }
    return top;
  },
  /**
    * 移除对象空值
    * @param target 需要处理的目标对象
    * @param ruleOut 为空值时不移除的键名集合或key，包括 key 的所有子级
    * @param emptyAllClean 当子级为空时，递归清空父级, 默认为 false
    * @returns
    */
  removeEmpty: (target, ruleOut, emptyAllClean) => {
    let outKey = [];
    if (
      !common.isEmpty(ruleOut) &&
      (typeof ruleOut == 'string' || Array.isArray(ruleOut))
    ) {
      outKey = typeof ruleOut == 'string' ? [ruleOut] : ruleOut;
    } else if (typeof ruleOut == 'boolean') {
      emptyAllClean = ruleOut;
    }
    const hand = (option, clean = true) => {
      if (!common.isEmpty(option)) {
        let newObj;
        if (Array.isArray(option)) {
          newObj = [];
          option.forEach(item => {
            if (!common.isEmpty(item)) {
              if (common.isObject(item)) {
                const newVal = hand(item, false);
                if (!emptyAllClean && !clean) {
                  common.isEmpty(newVal) &&
                    common.isJson(item)
                    ? newObj.push(item)
                    : newObj.push(newVal);
                } else {
                  !common.isEmpty(newVal) &&
                    newObj.push(newVal);
                }
                // !common.isEmpty(newVal) && newObj.push(newVal);
              } else {
                newObj.push(item);
              }
            }
            // else if (!emptyAllClean && !clean && common.isObject(item)) {
            //   newObj.push(item);
            // }
          });
        } else if (common.isJson(option)) {
          newObj = {};
          Object.keys(option).forEach(key => {
            if (!outKey.includes(key)) {
              if (
                !common.isEmpty(option[key]) &&
                option[key] !== 'web-null'
              ) {
                if (common.isObject(option[key])) {
                  const newVal = hand(option[key], false);
                  if (!emptyAllClean && !clean) {
                    // newObj[key] = common.isEmpty(newVal) ? option[key] : newVal;
                    newObj[key] = newVal;
                  } else {
                    !common.isEmpty(newVal) &&
                      (newObj[key] = newVal);
                  }
                } else {
                  newObj[key] = option[key];
                }
              }
            } else {
              newObj[key] = option[key];
            }
          });
        }
        return newObj;
      }
      return option;
    };
    return hand(common.copy(target));
  },
  // 将数组转化为对象
  arrayToObj(arr, key) {
    if (!Array.isArray(arr)) return arr;
    let [temp, objKey] = [{}, key || "value"];
    arr.forEach((item) => {
      temp[item[objKey]] = item;
    });
    return temp;
  }
};
export default common;

import request from '@/assets/scripts/request';

// 加载岗位列表
export function getPostsList(data){
  return request({
    url: '/role/findName',
    method: 'post',
    data
  })
}

// 加载菜单按钮权限树
export function getRoleList(ID){
  return request({
    url: '/menu/findTreeByRoleId?roleId='+ID,
    method: 'get',
  })
}

// 岗位编辑更新保存
export function postsUpdate(data){
  return request({
    url: '/role/updateSysRole',
    method: 'post',
    data
  })
}

// 岗位新增保存
export function postsAdd(data){
  return request({
    url: '/role/add',
    method: 'post',
    data
  })
}

// 岗位删除
export function roleDelete(ID){
  return request({
    url: '/role/deleteById?roleId='+ID,
    method: 'get',
  })
}

// 岗位新增保存
export function addPermission(data){
  return request({
    url: '/role/addPermission',
    method: 'post',
    data
  })
}

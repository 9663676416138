import Vue from 'vue';
import Router from 'vue-router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { getToken, clearToken } from '../assets/scripts/user';
import store from '../store';

// 引入客户管理路由配置
import customer_model from './customer_model';

// 引入系统设置路由配置
import system_model from './system_model';

// 引入包裹管理路由配置
import packageManage_model from './packageManage_model';

// 引入费用管理路由配置
import cost_model from './cost_model';

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: '/',
  scrollBehavior: () => ({ y: 0 }),
  routes: [

    {
      path: '*',
      component: () => import('../views/errorPage/404'),
    },
    {
      name: '404',
      path: '/404',
      component: () => import('../views/errorPage/404'),
      meta: { requireAuth: false }
    },

    {
      name: '登录页',
      path: '/login',
      component: () => import('../views/login/login'),
      meta: { requireAuth: false }
    },

    {
      name: '网站首页',
      path: '/',
      component: () => import('../views/home'),
      meta: { requireAuth: true },
      children: [

        {
          path: 'index',
          name: '首页',
          component: () => import('../views/index'),
          meta: { requireAuth: true }
        },

        // 客户管理模块 begin
        ...customer_model,
        // 客户管理模块 end

        // 系统设置模块 begin
        ...system_model,
        // 系统设置模块 end

        // 包裹管理模块 begin
        ...packageManage_model,
        // 系统设置模块 end

        // 费用管理模块 begin
        ...cost_model
        // 费用管理模块 end


      ]
    }

  ]
})


router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.name) {
    if (store.state.initConfig && JSON.stringify(store.state.initConfig) !== '{}') {
      document.title = `${store.state.initConfig.systemName}-` + to.name
    } else {
      document.title = '商户端-' + to.name
    }
  }

  // 去除数据加载进度条
  NProgress.done();

  // 获取初始化设置数据
  store.dispatch('getInitConfig').then((data) => {
    if (data) {
      document.title = `${store.state.initConfig.systemName}-` + to.name;
    }
  });

  // 当不需要检验时 直接走 例如登录页 404啊
  if (!to.meta.requireAuth) {
    return next();
  }

  // 当token不存在时 需要用户重新去登录
  if (!getToken()) {
    return next('/login');
  }

  // 数据加载进度条 开始加载
  NProgress.start();

  // 接下来就是检验的时刻
  store.dispatch('getNavs')
    .then(({ map_ }) => {

      // 为空就是菜单为空
      if (JSON.stringify(map_) == '{}') {
        alert("当前获取菜单数据为空!");
        NProgress.done()
        return next('/login');
      }

      let toPath = to.path.replace(/\/$/, '');

      // 判断需要改密码，123456的情况下跳转到强制改密码
      if (!toPath || store.state.currentUser.updatePassword && toPath !== '/index') {
        return next('/index');
      }

      // 当权限不存在时 去404
      if (!map_[toPath]) {
        NProgress.done();
        return next('/404');
      }

      // 此处去设置当前激活的一级菜单 二级菜单 和 底部固定的tab
      store.commit('setMenu', toPath);

      next();
    })
    .catch(_ => {
      NProgress.done()
      // 出错之后
      sessionStorage.setItem('return_url', window.location.pathname);
      clearToken();
      window.location.href = '/login';
    })
})

router.afterEach((to, from) => {
  // 去除数据加载进度条
  NProgress.done()
})

export default router

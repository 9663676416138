import Vue from 'vue';

// 使用 v-decimalsNum="2" 如果为0，默认保留两位
Vue.directive('decimalsNum', {

  inserted: function (el, binding, vnode){

    // 获取到input元素
    let targetObj = el.firstElementChild;

    // 获取指定保留多少位小数
    let pos = binding.value && binding.value !== 0 ? binding.value : 2;

    // 监听blur事件
    targetObj.addEventListener('blur',function(event){

      if(!binding.value) {
        return
      }

      let v = parseFloat(targetObj.value);

      if(isNumber(v)){

        let pow = Math.pow(10,pos);

        let result = (Math.round(v * pow) / pow).toString();

        targetObj.value = result;

        vnode.data.model.callback(result);

      }

    })

  }
})

const isNumber = val => typeof val === 'number' && val === val;
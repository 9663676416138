import request from '../../../assets/scripts/request';

// 获取/搜索列表
export function getDepartmentsList(params){
  return request({
    url: '/deptManage/getAllDept',
    method: 'post',
    params
  })
}

// 新增
export function addDepartment(data){
  return request({
    url: '/deptManage/addDept',
    method: 'post',
    data
  })
}

// 编辑
export function editDepartment(data){
  return request({
    url: '/deptManage/editDeptById',
    method: 'post',
    data
  })
}

// 删除
export function deleteDepartment(params){
  return request({
    url: '/deptManage/delDeptById',
    method: 'post',
    params
  })
}

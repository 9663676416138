import request from '../../../assets/scripts/request';

// 获取/搜索列表
export function getStaffsList(params){
  return request({
    url: '/employee/queryEmployeeList',
    method: 'get',
    params
  })
}
// 新增
export function addStaff(data){
  return request({
    url: '/employee/addEmployee',
    method: 'post',
    data: Object.assign(data, {companyId: -1})
  })
}

// 编辑
export function editStaff(data){
  return request({
    url: '/employee/editEmployee',
    method: 'post',
    data
  })
}
// 重置密码
export function resetStaffPassword(ID) {
  return request({
    url: '/employee/resetPassword?userId=' + ID,
    method: 'get',
  })
}
// 启用
export function enableStaff(params) {
  return request({
    url: '/employee/enableEmployee',
    method: 'post',
    params,
  })
}
// 禁用状态
export function disableStaff(params) {
  return request({
    url: '/employee/disabledEmployee',
    method: 'post',
    params,
  })
}
export function getStaffInfoById(params) {
  return request({
    url: '/employee/queryEmployeeInfo',
    method: 'get',
    params,
  })
}

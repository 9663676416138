// 商户管理模块

export default [
	{
        name: "客户管理",
        path:'customers/myCustomers/customersManage',
        component: () => import('@/views/customers/myCustomers/customersManage'),
        meta: {requireAuth: true}
    },
    {
        name: "客户的邮寄方式",
        path:'customers/myCustomers/customerNetAndEmail',
        component: () => import('@/views/customers/myCustomers/customerNetAndEmail'),
        meta: {requireAuth: true}
    },
    {
        name: "运营首页",
        path:'customers/operationDataReport/index',
        component: () => import('@/views/customers/operationDataReport/index'),
        meta: {requireAuth: true}
    },
    {
        name: "每日货量",
        path:'customers/operationDataReport/dayShipmentVolume',
        component: () => import('@/views/customers/operationDataReport/dayShipmentVolume'),
        meta: {requireAuth: true}
    },
    {
        name: "客户货量分析",
        path:'customers/operationDataReport/customerShipmentVolume',
        component: () => import('@/views/customers/operationDataReport/customerShipmentVolume'),
        meta: {requireAuth: true}
    },
    {
        name: "渠道货量分析",
        path:'customers/operationDataReport/channlShipmentVolume',
        component: () => import('@/views/customers/operationDataReport/channlShipmentVolume'),
        meta: {requireAuth: true}
    },
    {
        name: "货量周对比",
        path:'customers/operationDataReport/goodsWeekContrast',
        component: () => import('@/views/customers/operationDataReport/goodsWeekContrast'),
        meta: {requireAuth: true}
    },
    {
        name: "货量对比",
        path:'customers/operationDataReport/goodsContrast',
        component: () => import('@/views/customers/operationDataReport/goodsContrast'),
        meta: {requireAuth: true}
    },
    {
        name: "货量环比",
        path:'customers/operationDataReport/goodsRingRatio',
        component: () => import('@/views/customers/operationDataReport/goodsRingRatio'),
        meta: {requireAuth: true}
    }

]

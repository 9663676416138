// 费用管理模块

export default [

  // 价格管理 begin
  {
    name: '价格表管理',
    path: 'cost/pPrice/priceManageModel',
    component: () => import('@/views/cost/pPrice/priceManageModel'),
    meta: {requireAuth: true},
  },
  {
    name: '价格表价格试算',
    path: 'cost/pPrice/priceManageTrial',
    component: () => import('@/views/cost/pPrice/priceManageTrial'),
    meta: {requireAuth: true},
  },
  {
    name: '平台价格试算',
    path: 'cost/pPrice/priceTrial',
    component: () => import('@/views/cost/pPrice/priceTrial'),
    meta: {requireAuth: true},
  },
  {
    name: '价格折扣规则',
    path: 'cost/pPrice/discountRule',
    component: () => import('@/views/cost/pPrice/discountRule'),
    meta: {requireAuth: true},
  },
  {
    name: '客户价格试算',
    path: 'cost/pPrice/customerPriceTrial',
    component: () => import('@/views/cost/pPrice/customerPriceTrial'),
    meta: {requireAuth: true},
  },
  {
    name: '处理费自动规则',
    path: 'cost/pPrice/processingFeeRule',
    component: () => import('@/views/cost/pPrice/processingFeeRule'),
    meta: {requireAuth: true},
  },
  // 价格管理 end

  // 客户对账 begin
  {
    name: '客户的费用',
    path: 'cost/customerReconciliation/customerCost',
    component: () => import('@/views/cost/customerReconciliation/customerCost'),
    meta: {requireAuth: true},
  },
  {
    name: '客户的每日账单',
    path: 'cost/customerReconciliation/customerBill',
    component: () => import('@/views/cost/customerReconciliation/customerBill'),
    meta: {requireAuth: true},
  },
  {
    name: '客户的附加费用',
    path: 'cost/customerReconciliation/customerTransportCost',
    component: () => import('@/views/cost/customerReconciliation/customerTransportCost'),
    meta: {requireAuth: true},
  },
  {
    name: '客户的其他费用',
    path: 'cost/customerReconciliation/customerOtherCost',
    component: () => import('@/views/cost/customerReconciliation/customerOtherCost'),
    meta: {requireAuth: true},
  },
  {
    name: '客户的调账费用审核',
    path: 'cost/customerReconciliation/customerTransfer',
    component: () => import('@/views/cost/customerReconciliation/customerTransfer'),
    meta: {requireAuth: true},
  },
  {
    name: '客户退款审批',
    path: 'cost/customerReconciliation/refundsVerify',
    component: () => import('@/views/cost/customerReconciliation/refundsVerify'),
    meta: {requireAuth: true},
  },
  {
    name: '客户充值审批',
    path: 'cost/customerReconciliation/customerAddCostVerify',
    component: () => import('@/views/cost/customerReconciliation/customerAddCostVerify'),
    meta: {requireAuth: true},
  },
  {
    name: '客户充值确认',
    path: 'cost/customerReconciliation/customerAddCostConfirm',
    component: () => import('@/views/cost/customerReconciliation/customerAddCostConfirm'),
    meta: {requireAuth: true},
  },
  {
    name: '客户充值授信管理',
    path: 'cost/customerReconciliation/customerAddCostManage',
    component: () => import('@/views/cost/customerReconciliation/customerAddCostManage'),
    meta: {requireAuth: true},
  },
  // 客户对账 end

  // 平台对账 begin
  {
    name: '我的费用',
    path: 'cost/platformReconciliation/myCost',
    component: () => import('@/views/cost/platformReconciliation/myCost'),
    meta: {requireAuth: true},
  },
  {
    name: '我的每日账单',
    path: 'cost/platformReconciliation/myBill',
    component: () => import('@/views/cost/platformReconciliation/myBill'),
    meta: {requireAuth: true},
  },
  {
    name: '我的退款',
    path: 'cost/platformReconciliation/myRefunds',
    component: () => import('@/views/cost/platformReconciliation/myRefunds'),
    meta: {requireAuth: true},
  },
  {
    name: '我的充值管理',
    path: 'cost/platformReconciliation/myAddCostManage',
    component: () => import('@/views/cost/platformReconciliation/myAddCostManage'),
    meta: {requireAuth: true},
  },
  {
    name: '我的财务流水账',
    path: 'cost/platformReconciliation/myFinancialFlows',
    component: () => import('@/views/cost/platformReconciliation/myFinancialFlows'),
    meta: {requireAuth: true},
  },
  // 平台对账 end

  // 财务报表 begin
  {
    name: '毛利报表',
    path: 'cost/financialReport/profitReport',
    component: () => import('@/views/cost/financialReport/profitReport'),
    meta: {requireAuth: true},
  },
  {
    name: '客户货量报表',
    path: 'cost/financialReport/customerShipmentCount',
    component: () => import('@/views/cost/financialReport/customerShipmentCount'),
    meta: {requireAuth: true},
  },
  {
    name: '客户月度货量统计',
    path: 'cost/financialReport/customerMonthShipment',
    component: () => import('@/views/cost/financialReport/customerMonthShipment'),
    meta: {requireAuth: true},
  },
  {
    name: '发货量统计',
    path: 'cost/financialReport/deliveryCountNum',
    component: () => import('@/views/cost/financialReport/deliveryCountNum'),
    meta: {requireAuth: true},
  },
  // 财务报表 end
]

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import fetch from './assets/scripts/request';
import * as utils from './assets/scripts/utils'
import ElementUI from 'element-ui';
import common from './assets/scripts/common';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/style/main.scss'
import './assets/scripts/lodash';
import '@/directives';
import '@/components/dyt-componnets/';

Vue.config.productionTip = false
Vue.prototype.$http = fetch;
Vue.prototype.$utils = utils;
Vue.prototype.$common = common;
// 定义全局分页可选列数
Vue.prototype.GlobalPageSize = [10, 50, 100, 150, 300];
Vue.use(ElementUI, { size: 'medium' });

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

/*
 * @Author: w
 * @Date: 2019-07-19 16:06:07
 * @LastEditors: w
 * @LastEditTime: 2019-11-27 10:34:22
 */
// 国内处理中心操作

export default [

    // 包裹收货预报 begin
    {
        name: "客户包裹预报",
        path: 'packageManage/pPackagePrediction/customerPrediction',
        component: () => import('@/views/packageManage/pPackagePrediction/customerPrediction'),
        meta: { requireAuth: true }
    },
    {
        name: "客户包裹收货任务",
        path: 'packageManage/pPackagePrediction/customerTask',
        component: () => import('@/views/packageManage/pPackagePrediction/customerTask'),
        meta: { requireAuth: true }
    },

    // 包裹收货预报 end

    // 包裹管理 begin
    {
        name: "全部包裹",
        path: 'packageManage/pPackageManage/largeCargo',
        component: () => import('@/views/packageManage/pPackageManage/largeCargo'),
        meta: { requireAuth: true }
    },
    {
        name: "全部包裹 ",
        path: 'packageManage/pPackageManage/allPackage',
        component: () => import('@/views/packageManage/pPackageManage/allPackage'),
        meta: { requireAuth: true }
    },
    {
        name: "未发运包裹",
        path: 'packageManage/pPackageManage/notIssuedPackage',
        component: () => import('@/views/packageManage/pPackageManage/notIssuedPackage'),
        meta: { requireAuth: true }
    },
    {
        name: "异常包裹",
        path: 'packageManage/pPackageManage/exceptionPackage',
        component: () => import('@/views/packageManage/pPackageManage/exceptionPackage'),
        meta: { requireAuth: true }
    },
    {
        name: "跟踪号有更新",
        path: 'packageManage/pPackageManage/trackingNumberUpdate',
        component: () => import('@/views/packageManage/pPackageManage/trackingNumberUpdate'),
        meta: { requireAuth: true }
    },
    {
        name: "包裹查询",
        path: 'packageManage/pPackageManage/packageSearch',
        component: () => import('@/views/packageManage/pPackageManage/packageSearch'),
        meta: { requireAuth: true }
    },
    {
        name: "货箱查询",
        path: 'packageManage/pPackageManage/boxesSearch',
        component: () => import('@/views/packageManage/pPackageManage/boxesSearch'),
        meta: { requireAuth: true }
    },
    {
        name: "交货清单查询",
        path: 'packageManage/pPackageManage/deliverySearch',
        component: () => import('@/views/packageManage/pPackageManage/deliverySearch'),
        meta: { requireAuth: true }
    },
    {
        name: "包裹发货查询",
        path: 'packageManage/pPackageManage/packageDeliverSearch',
        component: () => import('@/views/packageManage/pPackageManage/packageDeliverSearch'),
        meta: { requireAuth: true }
    },

    // 包裹管理 end

    // 包裹操作 begin
    {
        name: "整袋收货扫描",
        path: 'packageManage/pPackageOperation/boxesScan',
        component: () => import('@/views/packageManage/pPackageOperation/boxesScan'),
        meta: { requireAuth: true }
    },
    {
        name: "单个包裹收货扫描",
        path: 'packageManage/pPackageOperation/packageScan',
        component: () => import('@/views/packageManage/pPackageOperation/packageScan'),
        meta: { requireAuth: true }
    },
    {
        name: "包裹称重扫描",
        path: 'packageManage/pPackageOperation/packageWeightScan',
        component: () => import('@/views/packageManage/pPackageOperation/packageWeightScan'),
        meta: { requireAuth: true }
    },
    {
        name: "包裹下单监测",
        path: 'packageManage/pPackageOperation/packageOrder',
        component: () => import('@/views/packageManage/pPackageOperation/packageOrder'),
        meta: { requireAuth: true }
    },
    {
        name: "包裹补打面单扫描",
        path: 'packageManage/pPackageOperation/packageRemedialScan',
        component: () => import('@/views/packageManage/pPackageOperation/packageRemedialScan'),
        meta: { requireAuth: true }
    },
    {
        name: "装袋扫描",
        path: 'packageManage/pPackageOperation/packScan',
        component: () => import('@/views/packageManage/pPackageOperation/packScan'),
        meta: { requireAuth: true }
    },
    {
        name: "箱货称重扫描",
        path: 'packageManage/pPackageOperation/boxesWeightScan',
        component: () => import('@/views/packageManage/pPackageOperation/boxesWeightScan'),
        meta: { requireAuth: true }
    },
    {
        name: "交货清单",
        path: 'packageManage/pPackageOperation/shipmentBill',
        component: () => import('@/views/packageManage/pPackageOperation/shipmentBill'),
        meta: { requireAuth: true }
    },
    {
        name: "处理中心交货",
        path: 'packageManage/pPackageOperation/billTrans',
        component: () => import('@/views/packageManage/pPackageOperation/billTrans'),
        meta: { requireAuth: true }
    },

    // 包裹操作 end

    // 自发头程 begin
    {
        name: "装箱扫描",
        path: 'packageManage/auto/packBoxScan',
        component: () => import('@/views/packageManage/auto/packBoxScan'),
        meta: { requireAuth: true }
    },
    {
        name: "自装箱代发",
        path: 'packageManage/auto/selfBoxing',
        component: () => import('@/views/packageManage/auto/selfBoxing'),
        meta: { requireAuth: true }
    },
    {
        name: "自发头程",
        path: 'packageManage/auto/autoHead',
        component: () => import('@/views/packageManage/auto/autoHead'),
        meta: { requireAuth: true }
    },
    {
        name: "头程发货",
        path: 'packageManage/auto/headShipment',
        component: () => import('@/views/packageManage/auto/headShipment'),
        meta: { requireAuth: true }
    },
    // 自发头程 end
    // 包裹退货 start
    {
        name: '退货管理',
        path: 'packageManage/packageReturn/returnAdmin',
        component: () => import('@/views/packageManage/packageReturn/returnAdmin.vue'),
        meta: { requireAuth: true }
    },
    // 包裹退货 end
]

/*
 * @Author: w
 * @Date: 2019-09-05 17:34:43
 * @LastEditors: w
 * @LastEditTime: 2019-12-10 14:01:28
 */
// 系统设置模块
export default [
    // 权限设置 begin
	{
        name: "用户信息",
        path:'system/authority/userMessage',
        component: () => import('@/views/system/authority/userMessage'),
        meta: {requireAuth: true}
    },
    {
        name: "修改密码",
        path:'system/authority/modifyPassword',
        component: () => import('@/views/system/authority/modifyPassword'),
        meta: {requireAuth: true}
    },
    {
        name: "部门管理",
        path:'system/authority/departmentManage',
        component: () => import('@/views/system/authority/departmentManage'),
        meta: {requireAuth: true}
    },
    {
        name: "岗位权限管理",
        path:'system/authority/postManage',
        component: () => import('@/views/system/authority/postManage'),
        meta: {requireAuth: true}
    },
    {
        name: "员工管理",
        path:'system/authority/staffManage',
        component: () => import('@/views/system/authority/staffManage'),
        meta: {requireAuth: true}
    },
    // 权限设置 end

    // 平台物流设置 end
    {
        name: "初始化设置",
        path:'system/platSetting/initialSetting',
        component: () => import('@/views/system/platSetting/initialSetting'),
        meta: {requireAuth: true}
    },
    {
        name: "授权的邮寄方式",
        path:'system/platSetting/authNetAndEmail',
        component: () => import('@/views/system/platSetting/authNetAndEmail'),
        meta: {requireAuth: true}
    },
    {
        name: "操作设置",
        path:'system/platSetting/operationSettings',
        component: () => import('@/views/system/platSetting/operationSettings'),
        meta: {requireAuth: true}
    },
    {
        name: "打印机设置",
        path:'system/platSetting/printSettings',
        component: () => import('@/views/system/platSetting/printSettings'),
        meta: {requireAuth: true}
    },
    {
        name: "货箱资料维护",
        path:'system/platSetting/BoxesData',
        component: () => import('@/views/system/platSetting/BoxesData'),
        meta: {requireAuth: true}
    },
    {
        name: "信息通知设置",
        path:'system/platSetting/informationNotice',
        component: () => import('@/views/system/platSetting/informationNotice'),
        meta: {requireAuth: true}
    },
    {
        name: "常用异常原因维护",
        path:'system/platSetting/abnormalCauses',
        component: () => import('@/views/system/platSetting/abnormalCauses'),
        meta: {requireAuth: true}
    },
    // 平台物流设置 end

]

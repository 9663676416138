// vuex 数据中心

import Vuex from 'vuex';
import Vue from 'vue';
import instance from '../assets/scripts/request';
import system from './modules/system'

Vue.use(Vuex);

// 提取链接 按钮权限提取
function extract(data, src) {
  src = src || {
    // 所有菜单
    map: {},
    // 页面按钮权限
    rights: {}
  }

  data.forEach(item => {
    let url = item.menuUrl;
    let btnType = item.menuType;
    if (url) {
      let key = url.replace(/\/$/, '');
      src.map[key] = true;
    }

    // 收集页面按钮权限
    if (btnType && btnType == 'BUTTON') {
      let keyVal = url.replace(/\/$/, '');
      // 判断是否存在当前key了
      if (src.rights.hasOwnProperty(keyVal)) {
        src.rights[keyVal].push(item.webCode)
      } else {
        src.rights[keyVal] = [item.webCode]
      }
    }

    if (item.childs && item.childs.length) {
      return extract(item.childs, src);
    }
  })
  return src;
}

/**
 * @description 找菜单
 * @param {*} data 菜单列表
 * @param {*} levelOne 是否为一级
 * @returns {Boolean}
 */
function find({data, path, levelOne, result}) {
  return data.some(item => {

    let url = item.menuUrl;

    if (url) {
      url = url.replace(/\/$/, '');

      if (url === path) {
        result.aside = item;

        if (levelOne) {
          result.nav = item;
        }

        return true;
      }
    }

    if (item.childs && item.childs.length) {
      let has = find({
        data: item.childs,
        path,
        levelOne: false,
        result
      });

      if (has && levelOne) {
        result.nav = item;
      }

      return has;
    }

    return false;
  })
}

function isIndexView(path) {
  return path === '/index';
}

// 添加当前路径
function extractUrl(data, path, flag) {
  data.currUrl = [data.menuName];
  data.childs.forEach(item => {
    item.currUrl = []
    item.currUrl.push(data.currUrl.join())
    item.currUrl.push(item.menuName)
    // 设置当前页面的面包屑
    if (item.menuUrl == path) {
      store.commit('setCurrUrl', item.currUrl);
      store.commit('setCurrActLink', item.menuName)
    }
    if (item.childs && item.childs.length && item.childs[0].menuType === 'MENU' && flag) {
      return extractUrl(item, path, false);
    }
  })
  return data;
}

const PROMIS_CACHE = {};
const store = new Vuex.Store({
  modules: {
    system,
  },
  state: {
    // 当前登录用户信息
    currentUser: {},
    // 一级菜单
    currentNavs: [],
    // 当前的一级菜单
    currentNav: null,
    // 二级菜单
    currentAsides: [],
    // 当前激活路由连接
    currentActiveLink: "",
    // 当前路径
    currentUrl: [],
    // 当前用户按钮权限
    currentRights: {},
    // 当前用户的页面权限路径集合
    currentUrlList: {},
    // 操作设置
    operationList: [],
    // OEM自定义信息
    initConfig: {}
  },

  mutations: {
    setCurrentUser(state, currentUser) {
      state.currentUser = currentUser;
    },

    setCurrentNavs(state, currentNavs) {
      state.currentNavs = currentNavs;
    },

    setCurrActLink(state, currActUrl) {
      state.currentActiveLink = currActUrl;
    },

    setCurrUrl(state, currUrl) {
      state.currentUrl = currUrl;
    },

    setCurrentRights(state, currRights) {
      state.currentRights = currRights;
    },

    setCurrentUrlList(state, currUrlList) {
      state.currentUrlList = currUrlList;
    },

    setInitConfig(state, initConfig) {
      state.initConfig = initConfig;
    },
    /**
     * @description 清空当前用户信息
     */
    clearUserData(state) {
      state.currentUser = {};
      state.currentNavs = [];
      state.currentNav = null;
      state.currentAsides = [];
      state.operationList = [];
      PROMIS_CACHE.USER = null;
      PROMIS_CACHE.MENU = null;
      PROMIS_CACHE.OPERATIONLIST = [];
      // 清空token
      removeToken();
    },

    /**
     * 设置一级菜单 二级菜单 底部tab相关
     */
    setMenu(state, path) {
      // 首页 清掉相关数据
      if (isIndexView(path)) {
        state.currentNav = null;
        state.currentAsides = [];
        return;
      }

      let navs = state.currentNavs;
      let result = {};

      find({
        data: navs,
        path,
        levelOne: true,
        result
      });

      let nav = extractUrl(result.nav, path, true);
      let aside = result.aside;

      if (!nav || !aside) {
        return;
      }

      // 相同值 不会触发二次diff
      state.currentNav = nav;
      state.currentAsides = nav.childs || [];
    },

    setOperationSetting(state, arr) {
      state.operationList = arr
    },

    resetOperationSetting(state) {
      state.operationList = [];
      delete PROMIS_CACHE.OPERATIONLIST;
    }
  },

  actions: {

    // 获取当前用户信息
    getCurrentUser(context) {
      // if (PROMIS_CACHE.USER) {
      //   return PROMIS_CACHE.USER;
      // }
      PROMIS_CACHE.USER = instance.get('/user/queryUserInfo')
        .then(res => {
          let resData = res.data;
          context.commit('setCurrentUser', resData);
          return JSON.parse(JSON.stringify(resData));
        });
      return PROMIS_CACHE.USER;
    },

    // 获取当前菜单数据
    getNavs(context) {

      if (PROMIS_CACHE.MENU) {
        return PROMIS_CACHE.MENU;
      }

      PROMIS_CACHE.MENU = context.dispatch('getCurrentUser')
        .then(resData => {
          return context.dispatch('getoperationSetting')
        })
        .then(resData => {
          return instance.get('/menu/getUserMenu')
        })
        .then(res => {
          let resData = res.data;
          context.commit('setCurrentNavs', resData);
          let src = extract(resData);
          // 这里添加首页权限
          src.map['/index'] = true;
          context.commit('setCurrentUrlList', src.map);
          let map_ = src.map;
          context.commit('setCurrentRights', src.rights);
          return { map_ };
        });
      return PROMIS_CACHE.MENU;
    },
    // 获取操作设置
    getoperationSetting(context) {
      if (PROMIS_CACHE.OPERATIONLIST) {
        return PROMIS_CACHE.OPERATIONLIST
      }
      PROMIS_CACHE.OPERATIONLIST = instance.post(`/operation/queryOperationList`)
        .then(({data}) => {
          context.commit('setOperationSetting', data);
          // setOperationSetting
          return data
        })

      return PROMIS_CACHE.OPERATIONLIST
    },
    // 获取OEM自定义信息
    getInitConfig(context) {
      if (PROMIS_CACHE.initConfig) {
        return PROMIS_CACHE.initConfig;
      }
      PROMIS_CACHE.initConfig = instance({url: '/initconfig/getbyurl', params: {pmsUrl: window.location.origin}})
        .then(({data}) => {
          context.commit('setInitConfig', data);
          return data
        })

      return PROMIS_CACHE.initConfig
    },
    reset_operationSetting(context) {
      context.commit('resetOperationSetting');

      context.dispatch('getoperationSetting').then((data) => {
        return PROMIS_CACHE.OPERATIONLIST
      })
      return PROMIS_CACHE.OPERATIONLIST
    }
  }
})

export default store

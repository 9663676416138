import {DepartmentManage} from '../../api/index';
import {StaffManage} from '../../api/index';
import {PostManage} from '../../api/index';
import {pickBy} from 'lodash/object';

const state = {
  departmentsList: [],
  staffsList: [],
  postsList: [],
  postsTotal: 0,
};

const mutations = {
  // 保存部门列表
  setDepartmentsList(state, payload) {
    state.departmentsList = payload.list;
  },
  // 保存部门列表
  setStaffsList(state, payload) {
    state.staffsList = payload.list;
  },
  // 保持岗位列表
  setPostsList(state, payload) {
    state.postsList = payload.list;
    state.postsTotal = payload.total;
  }
};

const actions = {
  /**
   * @desc 获取部门列表
   * @param params
   * @returns {PromiseLike<{total: *} | never> | Promise<{total: *} | never> | *}
   */
  getDepartmentsList({dispatch, commit, state, rootState}, params = {pageNum: 1, pageSize: 10}) {
    return DepartmentManage.getDepartmentsList(params).then(({code, msg, data}) => {
      commit({
        type: 'setDepartmentsList',
        list: data.rows,
      });
      return {
        total: data.total,
      }
    });
  },
  /**
   * @desc 获取员工列表
   * @param params
   * @returns {PromiseLike<{total: *} | never> | Promise<{total: *} | never> | *}
   */
  getStaffsList({dispatch, commit, state, rootState}, params = {pageNum: 1, pageSize: 10}) {
    const body = pickBy(params, (item) => item !== '');
    return StaffManage.getStaffsList(body).then(({code, msg, data}) => {
      commit({
        type: 'setStaffsList',
        list: data.rows,
      });
      return {
        total: data.total,
      }
    });
  },
  getPostsList({dispatch, commit, state, rootState}, body = {}) {
    return PostManage.getPostsList(body).then(({code, msg, data}) => {
      commit({
        type: 'setPostsList',
        list: data.list,
        total: data.total,
      });
    });
  }
};

export default {
  state,
  mutations,
  actions,
};
